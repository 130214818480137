module.exports = {
  ui: {
    fields: [
      'twInvoice.yearmonth',
      'twInvoice.no',
      'twInvoice.date',
      'twInvoice.amount.total',
      'twInvoice.amount.taxSales',
      'twInvoice.amount.tax',
      'twInvoice.canceledAt',
      'twInvoice.buyer.id',
      'twInvoice.carrier.id1',
      'src',
      'meta.note',
    ],
  },
}
