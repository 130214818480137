const $message = require('coact/store/message')

module.exports = function* invalidInvoice (payload, ctx) {
  try {
    const doc = ctx.getDoc()
    yield ctx.S.call(
      ctx.api.patch,
      {
        url: `/api/model/invoices/view/main/${doc._id}`,
        search: { action: 'invalid' },
      },
    )
    ctx.history.push(`/edit/invoices/view/main/${doc._id}`)
    yield ctx.S.put($message.act.send({ type: 'success', message: '發票已作廢' }))
  } catch (error) {
    yield ctx.S.put($message.act.send({ type: 'error', message: error.message }))
  }
}
