const mergeConfig = require('coact/config/mergeConfig')


const config = {
  models: {
    events: {
      title: '銷售活動資料',
      schema: require('./events/schema'),
    },
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
