const R = require('ramda')
const { App } = require('./pos/App')


const baseConfig = {
  title: '據點銷售系統',
  menu: require('./menu'),
  routes: {
    pos: {
      title: 'POS',
      path: '/pos',
      component: App,
    },
  },
}

const config = R.pipe(
  require('./event/installClient'),
  require('./product/installClient'),
  require('./invoice/installClient'),
  require('./retailOrder/installClient'),
)(baseConfig)

module.exports = config
