const schema = {
  type: 'Object',
  contents: {
    id: { type: 'String' }, // TODO move to root, rename to serviceId
    createdAt: { type: 'Date' },
    updatedAt: { type: 'Date' },
    clientId: { type: 'String' },
    buyer: {
      type: 'Object',
      contents: {
        id: { type: 'String', label: '統一編號' },
        name: { type: 'String' },
      },
    },
    type: { type: 'String' },
    carrier: {
      type: 'Object',
      label: '載具',
      contents: {
        type: { type: 'String', label: '載具類別' },
        id1: { type: 'String', label: '載具編碼' },
        id2: { type: 'String' },
      },
    },
    donation: { type: 'String', label: '捐贈發票碼' },
    amount: {
      type: 'Object',
      label: '金額',
      contents: {
        type: { type: 'String' },
        rate: { type: 'Number' },
        taxSales: { type: 'Number', label: '應稅銷售額' },
        freeTaxSales: { type: 'Number' },
        zeroTaxSales: { type: 'Number' },
        tax: { type: 'Number', label: '稅額' },
        total: { type: 'Number', label: '總額' },
      },
    },
    items: {
      type: 'Array',
      contents: [{
        type: 'Object',
        contents: {
          quantity: { type: 'Number', label: '數量' },
          price: { type: 'Number', label: '單價' },
          amount: { type: 'Number', label: '金額' },
          description: { type: 'String', label: '品名' },
          remark: { type: 'String', label: '備註' },
          relateNo: { type: 'String', label: '對應編碼' },
        },
      }],
    },
    account: { type: 'String' },
    date: { type: 'Date', label: '日期' },
    yearmonth: { type: 'String', label: '期別' },
    randomNo: { type: 'String' },
    no: { type: 'String', label: '號碼' },
    exportedAt: { type: 'Date' },
    uploadedAt: { type: 'Date' },
    canceledAt: { type: 'Date', label: '作廢時間' },
    voidedAt: { type: 'Date' },
    voidedExportedAt: { type: 'Date' },
    voidedUploadedAt: { type: 'Date' },
    QRCode1: { type: 'String' },
    QRCode2: { type: 'String' },
  },
}

module.exports = schema
