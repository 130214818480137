module.exports = [
  {
    category: '基本資料',
    features: ['events'],
  },
  {
    category: '銷售系統',
    features: ['pos'],
  },
  {
    category: '商品資料',
    features: ['products', 'skus'],
  },
  {
    category: '銷售資料',
    features: ['retailOrders:main', 'retailOrdersLineView'],
  },
  {
    category: '發票資料',
    features: ['invoices:main'],
  },
]
