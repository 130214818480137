const invalidInvoice = require('./invalidInvoice')

module.exports = {
  actions: {
    save: {
      authChecker: () => null,
      hotKey: null,
    },
    saveAndCreate: {
      authChecker: () => null,
    },
    saveAndCopy: {
      authChecker: () => null,
    },
    invalidInvoice: {
      text: '作廢發票',
      effect: { name: 'invalidInvoice' },
      props: { variant: 'outlined', color: 'primary' },
    },
  },
  effect: {
    invalidInvoice,
  },
  ui: {
    main: {
      name: 'formEditor',
      fields: [
        ['twInvoice.no', 'twInvoice.yearmonth', 'src'],
        ['twInvoice.date', 'twInvoice.canceledAt', 'twInvoice.amount.total'],
        ['twInvoice.buyer.id', 'twInvoice.carrier.type', 'twInvoice.carrier.id1', 'twInvoice.donation'],
        [{ path: 'meta.note', span: 3, multiline: true }],
      ],
    },
    items: {
      name: 'tableEditor',
      path: 'twInvoice.items',
      fields: [
        { path: 'description', width: 300 },
        { path: 'price', width: 70 },
        { path: 'quantity', width: 50 },
        { path: 'amount', width: 70 },
      ],
      deletable: false,
      copyable: false,
    },
  },
}
