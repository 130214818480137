/**
 * skus schema
 */

module.exports = {
  type: 'Object',
  contents: {
    key: { label: '單品編碼', type: 'String', unique: true, required: true },
    name: { label: '單品名稱', type: 'String', unique: true, required: true },
    barcode: { label: '單品條碼', type: 'String', unique: true, required: true },
    price: { label: '價格', type: 'Number', required: true },
    qty: { label: '數量', type: 'Number', required: true },

    product: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '所屬商品_id', reference: 'products', refKeys: ['key'] },
        key: { type: 'String', label: '商品型號' },
        name: { type: 'String', label: '商品名稱' },
        serie: { type: 'String', label: '商品系列' },
        supplier: { type: 'String', label: '供應商' },
      },
    },

    spec: {
      label: '規格',
      type: 'Object',
      contents: {
        size: { type: 'String', label: '尺寸' },
        color: { type: 'String', label: '色碼' },
        colorName: { type: 'String', label: '色名' },
      },
    },
  },
}
