const issueInvoice = require('./issueInvoice')

module.exports = {
  actions: {
    issueInvoice: {
      text: '開立發票',
      effect: { name: 'issueInvoice' },
      props: { variant: 'outlined', color: 'primary' },
    },
  },
  effect: {
    issueInvoice,
  },
  ui: {
    main: {
      name: 'formEditor',
      fields: [
        ['key', 'date', 'status'],
        ['payType', 'summary', 'amount'],
        ['buyerId', 'mobileBarcode', 'donation', 'citizenBarcode'],
        [{ path: 'note', span: 3, multiline: true }],
      ],
    },
    lines: {
      name: 'tableEditor',
      path: 'lines',
      fields: [
        { path: 'sku.id', width: 150 },
        { path: 'barcodeOnObject', width: 150 },
        { path: 'name', width: 150 },
        { path: 'qty', width: 50 },
        { path: 'price', width: 70 },
        { path: 'amount', width: 70 },
        { path: 'note', width: 70 },
      ],
      deletable: true,
      copyable: false,
    },
  },
}
