/**
 * products schema
 */

module.exports = {
  type: 'Object',
  contents: {
    key: { label: '商品型號', type: 'String', unique: true, required: true },
    name: { label: '商品名稱', type: 'String', unique: true, required: true },

    formattedKey: { label: '顯示型號', type: 'String', unique: true },
    keyOfSupplierSide: { label: '供應端商品碼', type: 'String', unique: true },

    price: { label: '價格', type: 'Number', required: true },

    description: { label: '商品簡介', type: 'String' },
    pdtBrand: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '品牌_id', reference: 'pdtBrands', refKeys: ['key'] },
        key: { tyep: 'String', label: '品牌名稱' },
      },
    },
    pdtSerie: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '系列_id', reference: 'pdtSeries', refKeys: ['key'] },
        key: { tyep: 'String', label: '系列名稱' },
      },
    },
    pdtType: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '類別_id', reference: 'pdtTypes', refKeys: ['key'] },
        key: { tyep: 'String', label: '類別名稱' },
      },
    },

    supplier: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '供應商_id', reference: 'suppliers', refKeys: ['key', 'code'] },
        key: { tyep: 'String', label: '供應商名稱' },
        code: { tyep: 'String', label: '供應商代碼' },
      },
    },
  },
}
