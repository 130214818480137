const mergeConfig = require('coact/config/mergeConfig')


const config = {
  models: {
    retailOrders: {
      schema: require('./retailOrders/schema'),
    },
    retailOrdersLineView: {
      title: 'retailOrdersLine',
      schema: require('./retailOrdersLineView/schema'),
    },
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
