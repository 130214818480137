"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FetchAllProducts = exports.ReimportProducts = exports.GetOriginalTotal = exports.GetSellingTotal = exports.GetSellingUnitPrice = void 0;
var DiscountType_1 = require("./DiscountType");
function GetSellingUnitPrice(item) {
    switch (item.discountType) {
        case DiscountType_1.DiscountType.None:
            return item.product.price;
        case DiscountType_1.DiscountType.Price:
            return item.discountPrice;
        case DiscountType_1.DiscountType.Ratio:
            return Math.floor((item.product.price * item.discountRatio + 50) / 100);
    }
}
exports.GetSellingUnitPrice = GetSellingUnitPrice;
function GetSellingTotal(order) {
    var result = 0;
    for (var _i = 0, _a = order.items; _i < _a.length; _i++) {
        var item = _a[_i];
        result += GetSellingUnitPrice(item) * item.amount;
    }
    result += order.discountAmount;
    return result;
}
exports.GetSellingTotal = GetSellingTotal;
function GetOriginalTotal(items) {
    var result = 0;
    for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
        var item = items_1[_i];
        result += item.product.price * item.amount;
    }
    return result;
}
exports.GetOriginalTotal = GetOriginalTotal;
var products = [
    {
        _id: "1",
        key: "ZZ-0000-1500#201104-171",
        barcode: "ZZ-0000-1500#201104-171",
        name: "【平原】冬季手套 抗水-AR-XS",
        price: 800,
    },
    {
        _id: "2",
        key: "ZZ-0000-1500#201104-172",
        barcode: "ZZ-0000-1500#201104-172",
        name: "【平原】冬季手套 抗水-AR-S",
        price: 800,
    },
    {
        _id: "3",
        key: "ZZ-0000-1500#201104-173",
        barcode: "ZZ-0000-1500#201104-173",
        name: "【平原】冬季手套 抗水-AR-M",
        price: 800,
    },
    {
        _id: "4",
        key: "ZZ-0000-1500#201104-174",
        barcode: "ZZ-0000-1500#201104-174",
        name: "【平原】冬季手套 抗水-AR-L",
        price: 800,
    },
    {
        _id: "5",
        key: "ZZ-0000-1500#201104-175",
        barcode: "ZZ-0000-1500#201104-175",
        name: "【平原】冬季手套 抗水-AR-XL",
        price: 800,
    },
];
function ReimportProducts(db) {
    return __awaiter(this, void 0, void 0, function () {
        var response, result, products, productsObjectStore, productsMap, _i, products_1, product;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch('/api/route/getPosSkus')];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    result = _a.sent();
                    products = result.data;
                    console.log(products);
                    productsObjectStore = db
                        .transaction("products", "readwrite")
                        .objectStore("products");
                    productsObjectStore.clear();
                    productsMap = {};
                    for (_i = 0, products_1 = products; _i < products_1.length; _i++) {
                        product = products_1[_i];
                        if (product.barcode in productsMap) {
                            console.error("Duplicated: " + product.barcode + " / " + product.name);
                        }
                        else {
                            productsMap[product.barcode] = product;
                        }
                    }
                    Object.values(productsMap).forEach(function (product) {
                        productsObjectStore.add(product);
                    });
                    return [2 /*return*/];
            }
        });
    });
}
exports.ReimportProducts = ReimportProducts;
function FetchAllProducts(db, setProducts) {
    db.transaction("products").objectStore("products").getAll().onsuccess = function (event) {
        setProducts(event.target.result);
    };
}
exports.FetchAllProducts = FetchAllProducts;
