const adaptSendingData = require('coact/saga/utils/adaptSendingData').default
const mapWithSchema = require('coact/utils/schema/mapWithSchema')

const $message = require('coact/store/message')

module.exports = function* issueInvoice (payload, ctx) {
  try {
    const doc = ctx.getDoc()
    const { schema } = ctx.page

    const sendingDoc = yield ctx.S.call(mapWithSchema, schema, adaptSendingData, doc)

    const { data: { retailOrder, invoice } } = yield ctx.S.call(
      ctx.api.post,
      {
        url: '/api/route/issueInvoiceFromPos',
        data: sendingDoc,
      },
    )

    console.log(invoice)
    ctx.history.push(`/edit/retailOrders/view/main/${retailOrder._id}`)

    yield ctx.S.put($message.act.send({ type: 'success', message: '發票已開立' }))
  } catch (error) {
    yield ctx.S.put($message.act.send({ type: 'error', message: error.message }))
  }
}
