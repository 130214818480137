const mergeConfig = require('coact/config/mergeConfig')


const config = {
  models: {
    products: {
      title: 'products',
      schema: require('./products/schema'),
    },
    skus: {
      title: 'skus',
      schema: require('./skus/schema'),
    },
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
