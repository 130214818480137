const R = require('ramda')


const keyFormatter = R.pathOr('', ['key'])

const dict = {
  retailOrders: {
    formatter: keyFormatter,
    view: 'main',
  },
  salesOrders: {
    formatter: keyFormatter,
    view: 'main',
  },
  recStatements: {
    formatter: keyFormatter,
    view: 'main',
  },
}

const formatterAndViewOfSrc = {
  formatter: (value) => {
    const ref = R.path(['$ref'], value)
    const doc = R.path(['$id'], value)
    const formatter = R.pathOr(null, [ref, 'formatter'], dict)
    return formatter ? formatter(doc) : `${doc || ''}`
  },
  view: (value) => {
    const ref = R.path(['$ref'], value)
    const view = R.pathOr(null, [ref, 'view'], dict)
    return view
  },
}

module.exports = formatterAndViewOfSrc
