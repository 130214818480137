module.exports = {
  ui: {
    fields: [
      'key',
      'date',
      'payType',
      'summary',
      'amount',
      'note',
    ],
  },
}
