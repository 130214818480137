const mergeConfig = require('coact/config/mergeConfig')

const clientConfig = require('./clientConfig')


const config = {
  models: {
    invoices: {
      views: {
        main: clientConfig,
      },
    },
  },
}

module.exports = fullConfig => mergeConfig(fullConfig, config)
