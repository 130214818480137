const schema = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    src: { type: 'DBRef', label: '銷售來源' },
    meta: {
      type: 'Object',
      contents: {
        note: { type: 'String', label: '內部備註' },
      },
    },
    twInvoice: {
      type: 'Object',
    },
  },
}

module.exports = schema
