module.exports = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    key: {
      type: 'String',
      label: '零售單號',
      counter: {
        key: () => '21AS',
        value: (key, value) => `${key}-${value}`,
      },
    },
    date: { type: 'Date', label: '日期', format: 'YYYY/MM/DD HH:mm' },
    status: { type: 'String', label: '狀態', options: ['新增', '完成', '取消'] },
    payType: { type: 'String', label: '付款方式', options: ['現金', '信用卡'] },
    note: { type: 'String', label: '備註' },

    amount: { type: 'Number', label: '金額' },
    summary: { type: 'String', label: '數量' },

    buyerId: { type: 'String', label: '發票統編' },
    mobileBarcode: { type: 'String', label: '手機載具號碼' },
    donation: { type: 'String', label: '捐贈發票碼' },
    citizenBarcode: { type: 'String', label: '自然人憑證載具' },

    lines: {
      type: 'Array',
      contents: [{
        type: 'Object',
        contents: {
          id: { type: 'ObjectId' },
          sku: {
            type: 'Object',
            contents: {
              id: { type: 'ObjectId', label: '單品', reference: 'skus', refKeys: ['key'] },
              key: { type: 'String', label: '單品編碼' },
              barcode: { type: 'String', label: '單品條碼' },
            },
          },
          barcodeOnObject: { type: 'String', label: '條碼' },
          name: { type: 'String', label: '名稱' },
          qty: { type: 'Number', label: '數量' },
          price: { type: 'Number', label: '單價' },
          amount: { type: 'Number', label: '計價' },
          note: { type: 'String', label: '項目備註' },
        },
      }],
    },
  },
}
