"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AppState;
(function (AppState) {
    AppState[AppState["Initial"] = 0] = "Initial";
    AppState[AppState["ShowTotal"] = 1] = "ShowTotal";
    AppState[AppState["Input"] = 2] = "Input";
    AppState[AppState["ItemSelected"] = 3] = "ItemSelected";
    AppState[AppState["ShowOrder"] = 4] = "ShowOrder";
})(AppState || (AppState = {}));
exports.default = AppState;
